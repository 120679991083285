/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, {useRef, useState} from "react";
import { useTranslation } from "react-i18next";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { SessionErrorDialog } from "../widgets/session-error-modal";
import ConfirmationDialog from "../widgets/confirmation-dialog/ConfirmationDialog";
//UI
import styled from "styled-components";

import {
  StyledGrid,
  StyledContainer,
  StyledTypography,
  StyledFooter,
} from "@hcl-commerce-store-sdk/react-component";

import Dialog from '@material-ui/core/Dialog';

//Custom
import { makeStyles } from "@material-ui/styles";
import logo_img from '../../static/images/ferrero-header-logo.png'
import easter_gio from '../../static/images/gio_avatar.png'
import { DialogContent } from "@material-ui/core";

const useStyle = makeStyles({
  footerLogo:{
    height: '3em'
  },
  easter:{
    pointerEvents: "none"
  }
});

const StyledFooterOverride = styled(StyledFooter)`
  margin-top: 3px;
`;

function Footer(props: any) {
  const { t } = useTranslation();
  const { mySite } = useSite();

  const [easterOpen, setEasterOpen] = useState(false);
  const count = useRef(0);
  const interval:any = useRef(0);
  

  const easter = () => {
    if(interval.current === 0)   {
      interval.current = setInterval(()=>{
        clearInterval(interval.current);
        interval.current = 0;
        count.current = 0;
        setEasterOpen(false);
      }, 13000);
      count.current++;
    }else{
      if(++count.current >= 13)  {
        setEasterOpen(true);
      }
    }
  }

  const Logo = () => {
    const classes = useStyle();
    return mySite != null ? (
      <div className="footer-logo">
        <img src={logo_img} className={classes.footerLogo} alt='footer-logo' onClick={easter} />    
      </div>
    ) : null;
  };

  const Copyright = () => {
    return (
      <div className="copyright">
        <StyledTypography variant="caption">
          &copy; <span>{new Date().getFullYear()}</span>
          <span> {t("Footer.Copyright")}</span>
        </StyledTypography>
      </div>
    );
  };

  const Easter = () => {
    const classes = useStyle();
    return (
      <Dialog open={easterOpen} className={easterOpen ? classes.easter : "" }>
        <DialogContent>
          <img src={easter_gio} style={{height: '38em'}} onClick={easter} alt='easter'/>   
          <div>
          <StyledTypography variant="caption">
            Saluti e Baci. Giovanni Grandinetti  
          </StyledTypography></div>
        </DialogContent>  
      </Dialog>
    )
  }

  return (
    <>
      <StyledFooterOverride>
        <StyledContainer>
          <StyledGrid container className="footer-top">
            <StyledGrid item xs={12} lg={6}>
              <Logo />
            </StyledGrid>
            <StyledGrid item xs={12} lg={6}>
              <Copyright />
            </StyledGrid>           
          </StyledGrid>
        </StyledContainer>
      </StyledFooterOverride>
      <SessionErrorDialog />
      <ConfirmationDialog />
      <Easter/>
    </>
  );
}

export default React.memo(Footer);
